// src/components/Footer.js
import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import "../styles/scss/footer.scss";

const Footer = () => {

  return (
    <footer>
      <div className="footer-signoff">graphic designer/ front end developer | <OutboundLink target="_blank" href="https://www.google.com/maps/d/embed?mid=1VrqWRglx0CbbA9pGo9dofAA-0_8&hl=en&ie=UTF8&t=p&msa=0&ll=51.600113766607095%2C-1.1248110000000056&spn=0.049581%2C0.087547&output=embed&z=14">Wallingford, Oxfordshire, UK.</OutboundLink></div>
      <small>&copy; {new Date().getFullYear()} Adrian Bright | built using React &amp; Gatsby</small>
    </footer>
  )
}

export default Footer