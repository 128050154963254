import React from "react"
import Helmet from "react-helmet"
import Banner from "../components/Banner"
import Footer from "../components/Footer"
import Header from "../components/Header"
import Container from "../components/Container"

export default function EmailPage() {
  return (
    <>
      <Helmet
          htmlAttributes={{
            lang: 'en',
          }}>
        <meta charSet="utf-8" />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;1,400;1,500&display=swap"
          rel="stylesheet"
        />
        <link rel="canonical" href="https://www.adrianbright.co.uk" />
        <title>Adrian Bright Front End Developer</title>
        <description>Adrian Bright Front End Developer Email</description>
      </Helmet>
      <Banner />
      <Header />

      <Container>
        <div className="row">
          <div className="col-12">
            <h1>email design</h1>
            <p className="m-auto p-5 text-center">Will update shortly</p>
          </div>
        </div>
      </Container>

      <Footer />
    </>
  )
}
